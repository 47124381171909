@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .slide-right-in {
    @apply animate-in slide-in-from-right fade-in-0 duration-500;
  }

  .fade-in {
    @apply animate-in fade-in-0 duration-500;
  }

  .slide-left-in {
    @apply animate-in slide-in-from-left fade-in-0 duration-500;
  }

  .slide-left-out {
    @apply animate-out slide-out-to-left fade-out-0 duration-500;
  }

  .slide-right-out {
    @apply animate-out slide-out-to-right fade-out-0 duration-500;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .form-elements {
    @apply flex flex-col gap-4;
  }

  .form-actions {
    @apply mt-7;
  }

  .input-focus {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:pointer-events-none disabled:opacity-50;
  }
}
